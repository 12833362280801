@import "../../_colors.scss";

.group-input {
  position: relative;

  label:not(.toggle) {
    display: block;
    width: 100%;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-bottom: 7px;
    padding: 0;
  }

  label.toggle {
    display: block;
    padding: 0;
  }

  select,
  input,
  textarea {
    width: 100%;
    background: #f9f9f9;
    border: 1px solid rgba(#dadada, 0.3);
    border-radius: 10px;
    height: 48px;
    padding: 13px 25px;
  }

  textarea {
    resize: none;
  }

  &.is-error {

    input,
    textarea,
    select,
    .select__control {
      border-color: #003cff !important;
    }

    .error {
      color: #adb5bd;
      position: absolute;
      padding: 0.5rem 0 0;
    }
  }

  select,
  input {
    background: #ffffff;
  }

  .basic-single {
    .select__control {
      width: 100%;
      background: #f9f9f9;
      border: 1px solid rgba(218, 218, 218, 0.3);
      box-shadow: none;
      border-radius: 10px;
      min-height: 48px;

      &:hover {
        border-color: rgba(218, 218, 218, 0.3);
        box-shadow: none;
      }

      .select__value-container,
      .select__input-container,
      input {
        min-height: 40px;
        margin: 0;
        padding: 0;
      }

      .select__value-container {
        padding: 0 20px;
      }
    }
  }
}

.vertical-resize {
  textarea {
    resize: vertical;
    overflow: auto;
  }
}

.tiebreaker-required {
  textarea {
    border: 1px solid #c9c9c9;
  }

  input {
    border: 1px solid #c9c9c9;
  }

}

.total-economy {
  input {
    color: #17e92c;
  }
}