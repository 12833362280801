.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 100%;
  gap: 20px;
  br {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    gap: 24px;
    flex-direction: row;
    .card {
      min-width: 250px;
      height: 138px;
    }
    br {
      display: block;
    }

  }
  .card {
    background: #FFFFFF;
    border: 1px solid rgba(#D8D8D8, .3);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    width: 100%;
    .is-loader{
      margin-top: 23px;
    }
    span {
      color: var(--colorCard);
    }
    &.simple {
      padding: 30px 25px 25px;
    }
    &.card-color {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 33px;
      .color {
        background: var(--colorCard);
        border-radius: 20px;
        width: 5px;
        height: 97px;
      }
      .info {
        display: flex;
        flex-direction: column;
      }
    }
  }
}


.sub-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
