:root {
  .layout {
    &.page.detalhes {
      label.toggle .labels {
        top: 7px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}


#detalhes {
  .body {
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
    padding: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid rgba(216, 216, 216, 0.3);
  }

  .abas {
    display: flex;

    li {
      padding: 10px 20px;
      color: #003cff;
      border: 1px solid transparent;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 10px;

      //&:hover, &.active {
      &.active {
        background: #003cff;
        border: 1px solid rgba(#d8d8d8, 0.3);
        border-radius: 10px;
        color: #ffffff;
      }

      span {
        width: 19px;
        height: 19px;
        background: #ff5468;
        border-radius: 100%;
        min-width: 19px;
        position: absolute;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -5px;
        top: -5px;
      }
    }

    li:hover {
      border: 1px solid rgba(#003cff, 0.3);
    }

  }

  .btn-avancar {
    .circle {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #007f0e;
      left: -16px;
      top: -16px;
    }
  }

  .table-processo {
    th {
      padding-bottom: 20px;
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba(#cdcdcd, 0.5);

        td {
          padding: 30px 0 25px 0;
        }
      }
    }
  }

  .aba-parecer {
    .status {
      cursor: pointer;
      position: relative;
      width: 250px;
      min-width: 250px;
      height: 80px;
      background: #ffffff;
      border: 1px solid rgba(#d8d8d8, 0.3);
      border-radius: 10px;
      padding: 20px;

      &.positivo {
        &.active {
          border-color: #17e92c;

          .pie-cicle {
            border-color: #17e92c;

            i {
              background-color: #17e92c;
            }
          }
        }
      }

      &.negativo {
        &.active {
          border-color: #ff5468;

          .pie-cicle {
            border-color: #ff5468;

            i {
              background-color: #ff5468;
            }
          }
        }
      }

      .pie-cicle {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 2px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          background-color: #d9d9d9;
        }
      }

      span {
        color: #a5a5a5;
      }
    }
  }

  .aba-comunicacao {
    .list-message {
      clear: both;
      padding-right: 16px;
      padding-bottom: 86px;

      .message {
        grid-column: span 2;
        border-radius: 10px;
        border: 1px solid #f1f1f1;
        background-color: #f9f9f9;
      }

      .message-my {
        grid-column: 2 / -1;
        border-radius: 10px;
        border: 1px solid #aabeff;
        background-color: #aabeff;
        position: relative;
        left: -30px;

        .sender {
          text-align: right;
        }

        .re-send {
          width: 95px;
          border: 1px solid white;
          text-align: center;
        }
      }
    }

    .form-message {
      padding-top: 32px;
      padding-bottom: 32px;
      //position: absolute;
      bottom: 32px;
      background-color: #fff;
      width: 57%;

      .send-message {
        .send-text {
          width: 90%;
        }

        .send-button {
          width: 95px;
        }
      }
    }
  }

  .progress-ans {
    position: absolute;
    top: 3%;
    font-size: 12px;
  }

  .day1 {
    left: 21.8%;
  }

  .day2 {
    left: 21.8% + 3.45%;
  }

  .day3 {
    left: 21.8% + (3.45% * 2);
  }

  .day4 {
    left: 21.8% + (3.45% * 3);
  }

  .day5 {
    left: 21.8% + (3.45% * 4);
  }

  .day6 {
    left: 21.8% + (3.45% * 5);
  }

  .day7 {
    left: 21.8% + (3.45% * 6);
  }

  .day8 {
    left: 21.8% + (3.45% * 7);
  }

  .day9 {
    left: 21.8% + (3.45% * 8);
  }

  .day10 {
    left: 21.8% + (3.45% * 9);
  }

  .day11 {
    left: 21.8% + (3.45% * 10);
  }

  .day12 {
    left: 21.8% + (3.45% * 11);
  }

  .day13 {
    left: 21.8% + (3.45% * 12);
  }

  .day14 {
    left: 21.8% + (3.45% * 13);
  }

  .day15 {
    left: 21.8% + (3.45% * 14);
  }

  .day16 {
    left: 21.8% + (3.45% * 15);
  }

  .day17 {
    left: 21.8% + (3.45% * 16);
  }

  .day18 {
    left: 21.8% + (3.45% * 17);
  }

  .day19 {
    left: 21.8% + (3.45% * 18);
  }

  .day20 {
    left: 21.8% + (3.45% * 19);
  }

  .day21 {
    left: 21.8% + (3.45% * 20);
  }

  @media screen and (max-width: 1366px) {
    .day1 {
      left: 26.7%;
    }

    .day2 {
      left: 26.7% + 3.25%;
    }

    .day3 {
      left: 26.7% + (3.25% * 2);
    }

    .day4 {
      left: 26.7% + (3.25% * 3);
    }

    .day5 {
      left: 26.7% + (3.25% * 4);
    }

    .day6 {
      left: 26.7% + (3.25% * 5);
    }

    .day7 {
      left: 26.7% + (3.25% * 6);
    }

    .day8 {
      left: 26.7% + (3.25% * 7);
    }

    .day9 {
      left: 26.7% + (3.25% * 8);
    }

    .day10 {
      left: 26.7% + (3.25% * 9);
    }

    .day11 {
      left: 26.7% + (3.25% * 10);
    }

    .day12 {
      left: 26.7% + (3.25% * 11);
    }

    .day13 {
      left: 26.7% + (3.25% * 12);
    }

    .day14 {
      left: 26.7% + (3.25% * 13);
    }

    .day15 {
      left: 26.7% + (3.25% * 14);
    }

    .day16 {
      left: 26.7% + (3.25% * 15);
    }

    .day17 {
      left: 26.7% + (3.25% * 16);
    }

    .day18 {
      left: 26.7% + (3.25% * 17);
    }

    .day19 {
      left: 26.7% + (3.25% * 18);
    }

    .day20 {
      left: 26.7% + (3.25% * 19);
    }

    .day21 {
      left: 26.7% + (3.25% * 20);
    }
  }
}

.btn-mais-info{
  padding: 10px;
  border: 1px solid white;
}

.btn-mais-info:hover {
  padding: 10px;
  border: 1px solid rgba(#000, 0.3);
  border-radius: 10px;
  color: black;
}

.basic-info{
  padding: 10px;
  //border: 1px solid #c9c9c9;
  background-color: rgb(247 247 247);
  border-radius: 10px;
  margin-bottom: 20px;
}