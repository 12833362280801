@import "../_colors.scss";
.menu-lateral {
  display: none;
  &.open {
    display: block;
    z-index: 10;
    width: 100%;
    .container-logo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .logo-img {
        width: 116px;
        margin: 0 0 55px 0;
        object-fit: contain;
      }
    }
  }
  .header-perfil {
    .user {
      margin-bottom: 40px;
    }
    .user, .dropdown-submenu {
      width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    &.open {
      display: block;
      width: 290px;
    }
    display: block;
    .header-perfil {
      display: none;
    }
    .container-logo {
      i {
        display: none;
      }
      .logo-img {
        width: 170px !important;
        margin: 0 auto 55px !important;
      }
    }
  }
  opacity: 1;
  &.close {
    width: 103px !important;
    min-width: 103px !important;
    padding: 40px 20px;
    //transition-property: all;
    //transition-duration: 100ms;
    //transition-timing-function: ease-out;
    span {
      justify-content: center;
    }
    label, .logo-img {
      display: none;
    }
    .logo-img-small {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 auto 55px;
    }
    a {
      justify-content: center;
    }
  }
  width: 290px;
  min-width: 290px;
  background: linear-gradient(147.1deg, #003CFF 0.65%, rgba(0, 255, 163, 0.85) 109.62%), #003CFF;
  position: fixed;
  bottom: 0;
  top: 0;
  padding: 40px 35px 40px 25px;
  z-index: 9;
  .logo-img-small {
    display: none;
  }
  nav {
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      margin-bottom: 17px;
      span {
        display: flex;
        gap: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        align-items: center;
        color: $whiteColor !important;
        padding: 17px;
        label {
          opacity: 1;
        }
      }
      &.is-active, &:hover {
        background: #002CBA;
        border-radius: 10px;
      }
    }
    .sub {
      border-top: 0.5px solid rgba(231, 231, 231, 0.24);
      margin: 0 10px;
      span {
        padding: 10px;
        width: 100%;
      }
      li {
        display: flex;
        align-items: center;
        margin: 0 0 0 -4px;
        div {
          width: 4px;
          height: 34px;
          border-radius: 3px;
        }
        &.is-active, &:hover {
          div {
            background: $whiteColor;
          }
        }
      }

    }
  }
}
