$whiteColor: #FFFFFF;
$blackColor: #000000;
$watermelonColor: #FF7283;
$blueColor: #003CFF;
$lightGrayColor: #C8C8C8;
$font-family: 'Open Sans';
$text-color: #0A0937;
$hr-color: rgba(151, 151, 151, 0.5);
$dustyGrayColor: #D2D2D2;
$suvaGreyColor: #929292;

.white {
  background-color: $whiteColor !important;
  &-text {
    color: $whiteColor !important;
    fill: $whiteColor !important;
  }
}

.black {
  background-color: $blackColor !important;
  &-text {
    color: $blackColor !important;
    fill: $blackColor !important;
  }
}

.watermelon {
  background-color: $watermelonColor !important;
  &-text {
    color: $watermelonColor !important;
    fill: $watermelonColor !important;
  }
}

.light-gray {
  background-color: $lightGrayColor !important;
  &-text {
    color: $lightGrayColor !important;
    fill: $lightGrayColor !important;
  }
}

.suva-grey {
  background-color: $suvaGreyColor !important;
  &-text {
    color: $suvaGreyColor !important;
    fill: $suvaGreyColor !important;
  }
}

.blue {
  background-color: $blueColor !important;
  &-text {
    color: $blueColor !important;
    fill: $blueColor !important;
  }
}
