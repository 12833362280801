@import "_colors.scss";

@for $i from 0 to 30 {
  $t: $i * 5;

  .i-#{$t} {
    width: #{$t}px !important;
    height: #{$t}px !important;
  }
}

@for $i from 0 to 8 {
  $t: $i * 45;

  .rotate-#{$t} {
    transform: rotate(#{$t}deg);
  }
}

i.icon {
  -webkit-mask-size: contain !important;
  mask-size: contain !important;
  -webkit-mask-position: center !important;
  mask-position: center !important;
  background-color: $blackColor;
  display: block;
  margin: 0;
  width: 18px;
  height: 18px;
}

.icon-globe {
  -webkit-mask: url("../../assets/images/icons/globe.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/globe.svg") no-repeat 100% 100%;
}

.icon-paper-plane {
  -webkit-mask: url("../../assets/images/icons/paper-plane.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/paper-plane.svg") no-repeat 100% 100%;
}

.icon-settings {
  -webkit-mask: url("../../assets/images/icons/settings.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/settings.svg") no-repeat 100% 100%;
}

.icon-users {
  -webkit-mask: url("../../assets/images/icons/users.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/users.svg") no-repeat 100% 100%;
}

.icon-list {
  -webkit-mask: url("../../assets/images/icons/list.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/list.svg") no-repeat 100% 100%;
}

.icon-menu-burger {
  -webkit-mask: url("../../assets/images/icons/menu-burger.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/menu-burger.svg") no-repeat 100% 100%;
}

.icon-bell {
  -webkit-mask: url("../../assets/images/icons/bell.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/bell.svg") no-repeat 100% 100%;
}

.icon-angle-small-down {
  -webkit-mask: url("../../assets/images/icons/angle-small-down.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/angle-small-down.svg") no-repeat 100% 100%;
}

.icon-document {
  -webkit-mask: url("../../assets/images/icons/document.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/document.svg") no-repeat 100% 100%;
}

.icon-menu-dots {
  -webkit-mask: url("../../assets/images/icons/menu-dots.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/menu-dots.svg") no-repeat 100% 100%;
}

.icon-cross-small {
  -webkit-mask: url("../../assets/images/icons/cross-small.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/cross-small.svg") no-repeat 100% 100%;
}

.icon-settings-sliders {
  -webkit-mask: url("../../assets/images/icons/settings-sliders.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/settings-sliders.svg") no-repeat 100% 100%;
}

.icon-arrow-left {
  -webkit-mask: url("../../assets/images/icons/arrow-left.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/arrow-left.svg") no-repeat 100% 100%;
}

.icon-arrow-right {
  -webkit-mask: url("../../assets/images/icons/arrow-left.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/arrow-left.svg") no-repeat 100% 100%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-trash {
  -webkit-mask: url("../../assets/images/icons/trash.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/trash.svg") no-repeat 100% 100%;
}

.icon-search {
  -webkit-mask: url("../../assets/images/icons/search.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/search.svg") no-repeat 100% 100%;
}

.icon-add-document {
  -webkit-mask: url("../../assets/images/icons/add-document.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/add-document.svg") no-repeat 100% 100%;
}

.icon-eye {
  -webkit-mask: url("../../assets/images/icons/eye.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/eye.svg") no-repeat 100% 100%;
}

.icon-book-alt {
  -webkit-mask: url("../../assets/images/icons/book-alt.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/book-alt.svg") no-repeat 100% 100%;
}

.icon-doctor {
  -webkit-mask: url("../../assets/images/icons/doctor.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/doctor.svg") no-repeat 100% 100%;
}

.icon-laptop {
  -webkit-mask: url("../../assets/images/icons/laptop.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/laptop.svg") no-repeat 100% 100%;
}

.icon-exit {
  -webkit-mask: url("../../assets/images/icons/exit.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/exit.svg") no-repeat 100% 100%;
}

.icon-user {
  -webkit-mask: url("../../assets/images/icons/user.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/user.svg") no-repeat 100% 100%;
}

.icon-building {
  -webkit-mask: url("../../assets/images/icons/building.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/building.svg") no-repeat 100% 100%;
}

.icon-check {
  -webkit-mask: url("../../assets/images/icons/check.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/check.svg") no-repeat 100% 100%;
}

.icon-edit {
  -webkit-mask: url("../../assets/images/icons/edit.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/edit.svg") no-repeat 100% 100%;
}

.icon-bolt {
  -webkit-mask: url("../../assets/images/icons/bolt.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/bolt.svg") no-repeat 100% 100%;
}

.icon-handshake {
  -webkit-mask: url("../../assets/images/icons/handshake.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/handshake.svg") no-repeat 100% 100%;
}

.icon-users-alt {
  -webkit-mask: url("../../assets/images/icons/users-alt.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/users-alt.svg") no-repeat 100% 100%;
}

.icon-down {
  -webkit-mask: url("../../assets/images/icons/down.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/down.svg") no-repeat 100% 100%;
}

.icon-duplicate {
  -webkit-mask: url("../../assets/images/icons/duplicate.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/duplicate.svg") no-repeat 100% 100%;
}

.icon-delete-document {
  -webkit-mask: url("../../assets/images/icons/delete-document.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/delete-document.svg") no-repeat 100% 100%;
}

.icon-file-pdf {
  -webkit-mask: url("../../assets/images/icons/file-pdf.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/file-pdf.svg") no-repeat 100% 100%;
}

.icon-file-png {
  -webkit-mask: url("../../assets/images/icons/file-png.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/file-png.svg") no-repeat 100% 100%;
}

.icon-file-jpg {
  -webkit-mask: url("../../assets/images/icons/file-jpg.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/file-jpg.svg") no-repeat 100% 100%;
}

.icon-cross-circle {
  -webkit-mask: url("../../assets/images/icons/cross-circle.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/cross-circle.svg") no-repeat 100% 100%;
}

.icon-checkbox {
  -webkit-mask: url("../../assets/images/icons/checkbox.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/checkbox.svg") no-repeat 100% 100%;
}

.icon-replace {
  -webkit-mask: url("../../assets/images/icons/replace.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/replace.svg") no-repeat 100% 100%;
}

.icon-comments-question {
  -webkit-mask: url("../../assets/images/icons/comments-question.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/comments-question.svg") no-repeat 100% 100%;
}

.icon-comment-info {
  -webkit-mask: url("../../assets/images/icons/comment-info.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/comment-info.svg") no-repeat 100% 100%;
}

.icon-info {
  -webkit-mask: url("../../assets/images/icons/info.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/info.svg") no-repeat 100% 100%;
}

.icon-question {
  -webkit-mask: url("../../assets/images/icons/question.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/question.svg") no-repeat 100% 100%;
}


.icon-lock {
  -webkit-mask: url("../../assets/images/icons/lock.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/lock.svg") no-repeat 100% 100%;
}

.icon-lock2 {
  -webkit-mask: url("../../assets/images/icons/lock2.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/lock2.svg") no-repeat 100% 100%;
}

.icon-chart {
  -webkit-mask: url("../../assets/images/icons/chart.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/chart.svg") no-repeat 100% 100%;
}

.icon-economy {
  -webkit-mask: url("../../assets/images/icons/economy.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/economy.svg") no-repeat 100% 100%;
}

.icon-dossie {
  -webkit-mask: url("../../assets/images/icons/dossie.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/dossie.svg") no-repeat 100% 100%;
}

.icon-print {
  -webkit-mask: url("../../assets/images/icons/print.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/print.svg") no-repeat 100% 100%;
}

.icon-power-bi {
  -webkit-mask: url("../../assets/images/icons/power-bi.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/power-bi.svg") no-repeat 100% 100%;
}

.icon-treatment {
  -webkit-mask: url("../../assets/images/icons/treatment.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/treatment.svg") no-repeat 100% 100%;
}

.icon-dut {
  -webkit-mask: url("../../assets/images/icons/dut.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/dut.svg") no-repeat 100% 100%;
}

.icon-important {
  -webkit-mask: url("../../assets/images/icons/important.svg") no-repeat 100% 100%;
  mask: url("../../assets/images/icons/important.svg") no-repeat 100% 100%;
}