.container-status {
  .arrow-previous, .arrow-next {
    position: absolute;
    z-index: 1;
    background: white;
    display: flex;
    align-items: center;
    width: 50px;
    justify-content: center;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .arrow-previous {
    left: 0;
  }

  .arrow-next {
    right: 0;
  }

  .lista-status {
    display: flex;
    width: 100%;
    overflow: hidden;
    gap: 16px;
    //position: relative;


    .dropdown-submenu {
      width: 280px;
      //height: 300px;
      position: fixed;
      right: auto;
      bottom: auto;

      background: #FFFFFF;
      border: 1px solid rgba(#E4E4E4, .5);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
      border-radius: 10px;
      padding: 15px;

      div {
        border-bottom: 1px solid rgba(#DFDFDF, .5);
      }

      p {
        color: #AAAAAA;
      }

      .circle {
        width: 15px;
        min-width: 15px;
        height: 15px;
        border-radius: 100%;
      }
      
      .circle-alert{
        background: #FF9900;
      }
      .circle-progress{
        background: #5b8ff9;
      }


    }
  }

  .junta-status {
    &:last-child {
      .status {
        &:before {
          content: none;
        }
      }
    }
    .status {
      position: relative;
      width: 250px;
      min-width: 250px;
      height: 80px;
      background: #FFFFFF;
      border: 1px solid rgba(#D8D8D8, .3);
      border-radius: 10px;
      padding: 20px;

      &:before {
        content: "";
        width: 8px;
        height: 1px;
        position: absolute;
        background: #D0D0D0;
        top: 50%;
        right: -13px;
      }

      .progress-status {
        position: relative;
        .pie-cicle {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: #17E92C;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 9px;
          left: 9px;
        }
        .negative{
          background: #ff9900;
        }
      }

      span {
        color: #A5A5A5;
      }
    }
  }
}

