@import "../_colors.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 27, 79, 0.63);
  backdrop-filter: blur(2px);
  z-index: 9999;

  .modal-main {
    position: fixed;
    background: white;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.0901961);
    border-radius: 10px;
    max-width: 1259px;
    max-height: 700px;
    height: max-content;
    overflow: auto;
    min-height: 100px;
  }

  .close {
    position: absolute;
    top: 45px;
    right: 25px;
    border: 0;
    background: none;
  }
}

.display-block {
  display: block !important;
}

.display-none {
  display: none;
}

.modal-cad-usuario {
  .options {
    padding: 40px 30px 30px 30px;
    min-width: 290px;
    height: 733px;
    background: #fbfbfb;
    border: 1px solid rgba(#d8d8d8, 0.3);
    border-radius: 10px;

    .stage {
      &.disabled {
        li.active {
          background: #e6e8ef;
        }
      }

      li {
        height: 55px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;

        &.active {
          background: #003cff;
          color: #ffffff;
        }
      }
    }
  }

  .form {
    padding: 40px 40px 0px 40px;

    .dados-usuario {
      overflow: auto;
      height: 618px;
      padding: 0 30px 30px 0;

      .etapa {
        display: none;

        &.ativa {
          display: block;
        }
      }
    }
    .dados-operadora {
      overflow: auto;
      height: 618px;
      padding: 0 30px 30px 0;

      .etapa {
        display: none;

        &.ativa {
          display: block;
        }
      }
    }
  }

  .convidados {
    padding: 10px 10px;
  }

  .aceito {
    color: #17e92c;
  }

  .recusado {
    color: #ff7283;
  }

  .box-list-scroll {
    height: 300px;
    overflow: scroll;
  }

  .list-scroll {
    min-height: 300px;
    height: inherit;
    overflow-y: scroll;
    padding-top: 8px;
    .list-scroll-item {
      padding: 10px 10px;
      border-top: 1px solid #dedede;
      &:hover {
        background-color: #f9f9f9;
        cursor: pointer;
        color: $blueColor;
      }
    }
  }
}
