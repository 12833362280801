.junta-historico {
  width: auto;
  min-width: 290px;
  height: 650px;
  background: #F9F9F9;
  border-radius: 10px;
  padding: 35px 10px 55px 20px;
  .item {
    position: relative;
    margin-top: 10px;
    p {
      color: #727272;
    }
    .circle {
      width: 14px;
      min-width: 14px;
      border-radius: 100%;
      height: 14px;
      background: #003CFF;
      margin: 3px 0;
      &:before {
        content: "";
        width: 1px;
        height: calc(100% + 20px);
        position: absolute;
        background: #003CFF;
        top: -10px;
        left: 5.5px;
      }
    }
  }
}
