.circle {
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 100%;
}

.circle-none {
    background: #fff;
    border: 1px solid lightgray;
}

.circle-progress {
    background: #84a1ff;
}

.circle-done {
    background: #17E92C;
}