.toggle {
  --width: 80px;
  --height: 36px;

  position: relative;
  display: inline-block;
  width: var(--width);
  min-width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #EAEAEA;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(var(--height) - 8px);
  height: calc(var(--height) - 8px);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background-color: #003CFF;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height) + 4px));
  left: 0;
}

.toggle .labels {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  color: #B0B0B0;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  width: calc(var(--width) - var(--height) + 3px);
  text-align: center;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: #ffffff;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  width: calc(var(--width) - var(--height) + 3px);
  text-align: center;
}

.toggle input:checked~.labels::after {
  opacity: 0;
}

.toggle input:checked~.labels::before {
  opacity: 1;
}
