@import "../_colors.scss";
.header {
  &.shadow {
    box-shadow: 0px 3px 6px #00000017 !important;
  }
  padding: 10px 25px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  position: fixed;
  background: $whiteColor;
  right: 0;
  left: 0;
  z-index: 10;
  .desktop {
    display: none;
  }
  .busca-site {
    display: none;
  }
  .container-options {
    gap: 40px;
  }
  @media screen and (min-width: 1024px) {
    padding: 30px 50px 10px 30px;
    height: auto;
    left: 103px;
    .logo-img {
      display: none;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: inherit;
    }
    .header-notificacoes {
      right: -253px !important;
    }
    .busca-site {
      display: block;
    }
    .container-options {
      gap: 30px;
    }
  }
  @media screen and (min-width: 1500px) {
    padding-right: 150px !important;
  }
}
@media screen and (min-width: 1024px) {
  .menu-open {
    .header {
      left: 290px;
    }
  }
}
.header-perfil {
  .user {
    &.active {
      border-color: #003cff;
    }
    background: #ffffff;
    border: 1px solid rgba(#b8b8b8, 0.3);
    border-radius: 10px;
    width: 222px;
    height: 48px;
    padding: 5px 20px 5px 8px;
    //font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      gap: 9px;
      img,
      .foto-padrao {
        width: 38px;
        height: 38px;
        border-radius: 100%;
        background-color: #f9f9f9 !important;
      }
    }
  }
  .dropdown-submenu {
    background: #ffffff;
    border: 1px solid rgba(218, 218, 218, 0.3);
    box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
    border-radius: 10px;
    width: 222px;
    padding: 5px 10px;
    top: 55px;
    a {
      cursor: pointer;
    }
  }
}

.icone-notificacao {
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  &.active {
    background: #f0f0f0;
  }
}

#widget-notifications {
  .alerta {
    width: 19px;
    height: 19px;
    background: #FF5468;
    border-radius: 100%;
    min-width: 19px;
    position: absolute;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -5px;
    top: -5px;
  }
}

.dropdown-submenu.header-notificacao {
  width: 442px !important;
  .notificacao {
    padding: 0 26px !important;
    border: none !important;
    height: auto !important;
    &:hover {
      background-color: transparent !important;
    }
    .fechar {
      color: #b7b7b7;
    }
  }
}

.dropdown-submenu.header-notificacoes,
.dropdown-submenu.header-notificacao {
  max-width: 397px;
  width: 90vw;
  background: #ffffff;
  border: 1px solid rgba(218, 218, 218, 0.3);
  box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
  border-radius: 10px;
  right: -80px;
  top: 55px;
  display: block;
  padding: 0;
  .line-bottom {
    border: 1px solid rgba(224, 224, 224, 0.5);
    margin: -17px 20px 5px;
  }
  .container-notificacao {
    height: auto;
    max-height: calc(100vh - 235px);
    overflow: auto;
  }
  .notificacao {
    padding: 12px 36px 21px 31px;
    height: auto;
    min-height: 100px;
    display: flex;
    &:hover {
      background: rgba(#f0f4ff, 0.5);
    }

    &.nao-lido {
      background: #f0f4ff;
      .alert-nao-lido {
        width: 10px;
        min-width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #003cff;
      }
    }
    .junta_medica {
      color: #a0a0a0;
    }
    .etapa {
      color: #003cff;
    }
  }
}
