@import "../_colors.scss";

.error-main{
    margin: auto; 
    width: 50%; 
    padding: 5% 0%;
    margin-top: 150px;
}

.error-background {
    background-image: url('../../../assets/images/login.png');
    width: 100%;
    height: 100px;
    position: absolute;
    background-attachment: inherit;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    left: 0;
    top: 0;
  }