.page-docs {
    width: 100%;
    height: 100%;
    padding: 0px !important;
    margin: 0px;
    background-color: gray;

    .doc-wrapper {
        margin: auto;
        margin: 0% 20% 0% 20%;
        pointer-events: none;

        img:first-child {
            padding-top: 10px;
        }

        img {
            border-bottom: 1px solid gray;
            padding-bottom: 10px;
        }
    }
}

.page-iframe {
    width: 100%;
    padding: 0px !important;
    margin: 0px;

    .iframe-wrapper {
        max-width: 100%;
        position: relative;
        margin-bottom: 0px;

        iframe {
            position: fixed;
            width: 100%;
            height: 100%;
        }
    }
}