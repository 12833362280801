.search {
  position: relative;
  input {
    background: #F9F9F9 !important;
    border: 1px solid rgba(#DADADA, .3);
    border-radius: 10px;
    width: 100%;
    max-width: 395px;
    height: 48px;
    padding: 15px 100px 15px 25px;
    @media screen and (max-width: 1180px) {
      padding: 15px;
    }
  }
  button {
    background: #84A1FF;
    color: #FFFFFF;
    border: 1px solid rgba(#D8D8D8, .3);
    border-radius: 10px;
    width: 90px;
    height: 35px;
    position: absolute;
    top: 6px;
    right: 6px;
    text-align: center;
    .is-loader{
      margin: 0 auto;
    }
  }
}
