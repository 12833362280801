.list-line {
  border-left: 1px solid #003cff;
  padding-bottom: 10px;
}

.list-circle {
  width: 14px;
  min-width: 14px;
  border-radius: 100%;
  height: 14px;
  background: #003CFF;
  margin: 1px -17px;
}

.dossie-header-session {
  margin-bottom: 30px;
  margin-top: 20px;
  font-size: 18px;
}

.list-header {
  font-size: 14px;
}

.font-green {
  color: #17e92c;
}

.email-doc {
  border: 1px solid gray;
  margin-bottom: 10px;
  padding-left: 5px;
}

