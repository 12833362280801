:root {
    .page {
        &.power-by {
            .layout-body {
                width: calc(100vw - 113px);
            }
            #container-main {
                padding: 0px !important;
                margin: 0px;
                .iframe-wrapper {
                    max-width: 100%;
                    height: 0;
                    position: relative;
                    padding-bottom: 56.25%;
                }
                .iframe-wrapper iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
