@import "../../_colors.scss";

:root {
  .layout {
    &.page.cadastro {
      label.toggle .labels {
        top: 12px;
        font-weight: 600;
      }
    }
  }
}

.form-header {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  .group-input {
    width: 100%;
    max-width: 500px;
  }
  .container-stages {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: calc(100vw - 70px);
    .stages {
      min-width: max-content;
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    .group-input {
      width: 280px;
    }
    .container-stages {
      width: auto;
    }
  }
}

.form-rodape {
  display: flex;
  justify-content: space-between;
  bottom: 40px;
  gap: 15px;
  button {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 187px;
    }
  }
}

.stages {
  background: #f8f8f8;
  border-radius: 10px;
  height: 60px;
  padding: 15px;
  &.current {
    .percentage {
      stroke: #003cff;
    }
    text {
      fill: #003cff;
    }
  }
  &.complete {
    circle {
      stroke: #17e92c;
    }
    text {
      fill: #17e92c;
    }
  }
  &.next {
    circle {
      stroke: #cbcbcb;
    }
    text {
      fill: #c9c9c9;
    }
    color: #c9c9c9;
  }
}

.empty-procedures {
  height: 310px;
  line-height: 310px;
  text-align: center;
  background: #fbfbfb;
  border-radius: 10px;
  p {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
  }
}

.list-procedures {
  .item {
    display: flex;
    gap: 60px;
    min-height: 120px;
    background: #ffffff;
    border: 1px solid rgba(#d8d8d8, 0.5);
    box-shadow: 0px 4px 8px rgb(223 223 223 / 25%);
    border-radius: 10px;
    padding: 20px 45px 20px 20px;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 768px) {
    .item {
      flex-direction: column;
      height: auto;
      gap: 10px !important;
      padding: 30px 20px;
      .form-container {
        max-width: 100% !important;
      }
      a {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.anexos {
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 0.5px solid #d8d8d8;
  border-radius: 10px;
  padding: 35px 50px 40px 50px;
  textarea {
    height: 91px;
  }
  .botoes {
    min-width: 190px;
  }
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    height: auto;
    gap: 40px !important;
    align-items: flex-start;
    padding: 30px 20px;
    .form-container {
      max-width: 100% !important;
    }
    .botoes {
      min-width: 100%;
      width: 100%;
    }
  }
}

.lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.input-search {
  input {
    background-color: #ffffff;
    &.search-open {
      border-radius: 10px 10px 0 0;
      border-bottom: none;
    }
  }
  i {
    position: absolute;
    top: 17px;
    right: 18px;
    z-index: 8;
  }
  .is-loader {
    position: absolute;
    top: 15px;
    right: 18px;
    z-index: 10;
  }
  .list-search {
    width: auto;
    min-width: 350px;
    background: #ffffff;
    /*border: 1px solid rgba(#dadada, 0.3);*/
    box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 0;
    position: absolute;
    z-index: 10;
    li {
      border-top: 1px solid rgba(#e2e2e2, 0.5);
      margin: 0 10px;
      padding: 20px 10px;
      cursor: pointer;
      & > div {
        margin-top: 0px;
        gap: 13px;
        .lines-3 {
          margin-top: 2px;
        }
        span.status {
          width: 10px;
          min-width: 10px;
          height: 10px;
          border-radius: 100%;
          background: #f7f7f7;
          display: inline-block;
          &.status-analise {
            background: #003cff;
          }
        }
      }
      .etapa {
        color: #003cff;
      }
      &:hover {
        background: rgba(#fafafa, 0.3);
      }
      &.list-seemore {
        padding: 0px;
        font-size: 18px;
        &:hover {
          color: $blueColor;
        }
      }
    }
  }

  .toggle .labels {
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
  }
}
