.tag-perfil {
  --tg-bg: #f9f9f9;
  padding-left: 7px;
  padding-right: 7px;
  width: 100%;
  min-width: 130px;
  height: 32px;
  background: var(--tg-bg) !important;
  border: 1px solid rgba(var(--tg-color), 0.5px) !important;
  color: var(--tg-color) !important;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.operadora {
  --tg-color: #003CFF;
  --tg-bg: #D0DBFF;
}
.operadoramaster {
  --tg-color: #003CFF;
  --tg-bg: #D0DBFF;
}
.desempatador {
  --tg-color: #FF9900;
  --tg-bg: #FFECD0;
}