@import "../_colors.scss";

.layout {
  display: flex;
}

.layout-body {
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: calc(100vw - 123px);
    left: 103px;

    &.menu-open {
      width: calc(100vw - 310px);
      left: 290px;
    }

    main {
      top: 93px !important;
      padding: 20px !important;
    }
  }

  @media screen and (min-width: 1500px) {
    main {
      top: 93px !important;
      //padding-right: 150px !important;
    }
  }

  left: 0;
  position: relative;
  min-width: min-content;

  main {
    position: relative;
    top: 60px;
    padding: 15px !important;
    width: inherit;
  }
}

.page-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 20px;
}

.title {
  font-size: 1.375rem !important;
  font-weight: 600 !important;
  line-height: 1.8333333333rem !important;
}


.subtitle {
  font-size: 18px;
  font-weight: 600 !important;
}

.dropdown-submenu {
  width: max-content;
  border-radius: 10px;
  padding: 20px 21px 28px 15px;
  background: #fff;
  border: 1px solid #ddd;
  border: 1px solid rgba(#c7c7c7, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s, opacity 0.2s, visibility 0s linear 0.2s;
  will-change: transform;
  position: absolute;
  width: fit-content;
  right: 0;
  margin: 0.8rem auto auto;
  z-index: 2;

  a {
    span {
      width: max-content;
    }
  }

  .menu-icon {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(#e3e3e3, 0.5);
    padding: 16px 10px;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }
}

.dropdown-submenu.visible {
  transform: translateY(-0.3rem);
  transition: transform 0.2s, opacity 0.2s, visibility linear;
  visibility: visible;
  opacity: 1;
}

.btn-dots {
  width: 36px;
  height: 35px;
  background: #f7f7f7;
  border-radius: 5px;

  &:hover,
  &.open {
    background-color: $blueColor;

    i {
      background: #f7f7f7 !important;
    }
  }
}

.cursor-help:hover {
  cursor: help
}

.badge {
  border-radius: 5px !important;
  min-width: 97px;
  color: #fff !important;
  text-align: center !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;

  &.badge-1 {
    background: #ff9900;
  }

  &.badge-1:after {
    content: 'Não iniciado';
  }

  &.badge-2,
  &.badge-3,
  &.badge-6,
  &.badge-7,
  &.badge-8,
  &.badge-9,
  &.badge-10,
  &.badge-11,
  &.badge-13,
  &.badge-14,
  &.badge-316,
  &.badge-317,
  &.badge-318 {
    background: #84a1ff;
  }

  &.badge-4 {
    background: #17E92C;
  }

  &.badge-4:after {
    content: 'Concluído';
  }

  &.badge-5 {
    background: #adadad;
  }

  &.badge-12 {
    background: #5f5d5e;
  }

  &.badge-5:after {
    content: 'Cancelado';
  }

  &.badge-12:after {
    content: 'Rascunho';
  }

  &.badge-atrasado {
    background: #ff7283;
  }

  &.badge-pausado {
    background: #ff7283;
  }

  &.badge-no-after:after {
    content: ''
  }

}

.badge-comunication {
  border-radius: 5px !important;
  padding: 0px 5px;
  min-width: 97px;
  color: #fff !important;
  text-align: center !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 20px;
  background-color: #9c9c9c;

  &.badge-2 {
    background: #84a1ff;
  }

  &.badge-3 {
    background: #17E92C;
  }

  &.badge-6 {
    background: #ff7283;
  }

}

.group-input select:not(.select__input),
.group-input input:not(.select__input) {
  padding: 9px 18px !important;
}

.group-input textarea:not(.select__input) {
  padding: 15px 15px !important;
}

.group-input select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.no-scroll {
  overflow: hidden !important;
}

/* #__react-alert__ > div > div > div{
  background-color: #fff !important;
  span{
    color: #0A0937 !important;
  }
} */

.debug {
  border: 1px solid red;
}

.mail-separator {
  width: 100%;
  height: 1px;
  border-bottom: 5px solid gray;
}

.mail-separator-half {
  width: 50px;
  height: 1px;
  border-bottom: 5px solid #003cff;
}

.junta-comunicacao {
  width: auto;
  min-width: 290px;
  height: auto;
  border-radius: 10px;
  padding: 35px 10px 55px 20px;

  .comunicacao-list {
    height: 650px !important;
  }

  .item {
    position: relative;
    margin-top: 10px;

    p {
      color: #727272;
    }

    .circle {
      width: 14px;
      min-width: 14px;
      border-radius: 100%;
      height: 14px;
      background: #003CFF;
      margin: 3px 0;

      &:before {
        content: "";
        width: 1px;
        height: calc(100% + 20px);
        position: absolute;
        background: #003CFF;
        top: -10px;
        left: 5.5px;
      }
    }
  }
}