@import "_colors.scss";
@import "_function.scss";
@import "icons.scss";

@font-face {
  font-family: "Open Sans";
  src: url(../../assets/fonts/OpenSans-Regular.ttf);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: $font-family;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow-x: hidden !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  color: $text-color;
}

body,
input,
select,
option,
textarea,
button {
  font-family: $font-family;
  font-size: 1em;
}

button.selected {
  background: #003cff !important;
}

.modal {
  z-index: 100 !important;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

@media screen and (max-width: 1280px) {

  html,
  body {
    font-size: 14px !important;
  }
}

*,
*::after,
*::before {
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
}

img {
  display: block;
  max-width: 100%;
  object-fit: cover;
}

a,
a:visited {
  text-decoration: none !important;
  color: inherit !important;
}

b,
strong,
.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 800;
}

.bold-red .fn-16 {
  font-weight: 800 !important;
  color: #ff5468;
}

.bold-red .fn-14 {
  font-weight: 800 !important;
  color: #ff5468;
}

.italic {
  font-style: italic !important;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  line-height: 1.2em;
}

h1.thin,
h2.thin,
h3.thin,
h4.thin,
h5.thin,
h6.thin {
  font-weight: 400;
}

h1.small {
  font-size: 1.6em;
}

p {
  line-height: 1.6em;
  word-break: break-word;
  font-family: $font-family, sans-serif;
}

p:not(:last-of-type) {
  margin-bottom: 20px;
}

hr {
  border-top: 1px dashed $hr-color;
}

hr.line {
  border: none;
  height: 1px;
  background-color: $hr-color;
}

label {
  font-size: 0.8em;
  padding: 0 5px;
}

label.normal {
  font-size: 1em;
}

.m-auto {
  margin: 0 auto;
  text-align: center;
}

.w600 {
  max-width: 600px;
  margin: 0 auto;
}

.lt-pd {
  padding: 10px 20px;
}

.sm-pd {
  padding: 20px;
}

.md-pd {
  padding: 40px 20px;
}

.bg-pd {
  padding: 80px 20px;
}

.block {
  display: block;
}

.inline-flex {
  display: inline-flex !important;
}

.-webkit-inline-box {
  display: -webkit-inline-box !important;
}

.grid {
  display: grid;
}

.lt-gap {
  grid-gap: 10px;
}

.sm-gap {
  grid-gap: 20px;
}

.md-gap {
  grid-gap: 40px;
}

.id-gap {
  grid-gap: 50px;
}

.bg-gap {
  grid-gap: 80px;
}

.col-16 {
  grid-template-columns: repeat(16, 1fr);
}

.col-14 {
  grid-template-columns: repeat(14, 1fr);
}

.col-12 {
  grid-template-columns: repeat(12, 1fr);
}

.col-11 {
  grid-template-columns: repeat(11, 1fr);
}

.col-10 {
  grid-template-columns: repeat(10, 1fr);
}

.col-9 {
  grid-template-columns: repeat(9, 1fr);
}

.col-8 {
  grid-template-columns: repeat(8, 1fr);
}

.col-7 {
  grid-template-columns: repeat(7, 1fr);
}

.col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-1 {
  grid-template-columns: repeat(1, 1fr);
}

.span-13 {
  grid-column: span 13;
}

.span-12 {
  grid-column: span 12;
}

.span-11 {
  grid-column: span 11;
}

.span-10 {
  grid-column: span 10;
}

.span-9 {
  grid-column: span 9;
}

.span-8 {
  grid-column: span 8;
}

.span-7 {
  grid-column: span 7;
}

.span-6 {
  grid-column: span 6;
}

.span-5 {
  grid-column: span 5;
}

.span-4 {
  grid-column: span 4;
}

.span-3 {
  grid-column: span 3;
}

.span-2 {
  grid-column: span 2;
}

.ai-gc {
  align-items: center;
}

.ai-ge {
  align-items: end;
}

.ai-gss {
  align-items: start;
}

.ai-gs {
  align-items: stretch;
}

.as-ge {
  align-self: end;
}

.as-gc {
  align-self: center;
}

.as-gs {
  align-self: start;
}

.ji-gc {
  justify-items: center;
}

.ji-ge {
  justify-items: end;
}

.ji-gss {
  justify-items: start;
}

.flex {
  display: flex;
}

.inline-block {
  display: inline-block;
}

.fw-w {
  flex-wrap: wrap;
}

.ac-fs {
  align-content: flex-start;
}

.fd-fc {
  flex-direction: column;
}

.ai-fc {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-fc {
  justify-content: center;
}

.jc-fe {
  justify-content: flex-end;
}

.js-fc {
  justify-self: center;
}

.js-fe {
  justify-self: end;
}

.js-fs {
  justify-self: start;
}

.no-shr {
  flex-shrink: 0;
}

@media screen and (max-width: 1024px) {

  .col-16,
  .col-14,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .col-4,
  .col-3,
  .col-2 {
    grid-template-columns: 1fr 1fr;
  }

  .span-13,
  .span-12,
  .span-11,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6,
  .span-5,
  .span-4,
  .span-3,
  .cl {
    grid-column: span 2;
  }

  .tablet-none {
    display: none;
  }
}

@media screen and (max-width: 768px) {

  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2 {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .span-12,
  .span-11,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6,
  .span-5,
  .span-4,
  .span-3,
  .span-2 {
    grid-column: span 1;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.link:hover {
  color: #003cff !important;
}

.color-white {
  color: #fff;
}

.color-gray {
  color: #dbdbdb;
}

@for $i from 0 to 30 {
  $t: $i * 5;
  $size: calculateRem(#{$t}, rem);

  .m-#{$t} {
    margin: #{$size} !important;
  }

  .mt-#{$t},
  .mv-#{$t} {
    margin-top: #{$size} !important;
  }

  .mb-#{$t},
  .mv-#{$t} {
    margin-bottom: #{$size} !important;
  }

  .mr-#{$t},
  .mh-#{$t} {
    margin-right: #{$size} !important;
  }

  .ml-#{$t},
  .mh-#{$t} {
    margin-left: #{$size} !important;
  }

  .p-#{$t} {
    padding: #{$size} !important;
  }

  .pt-#{$t},
  .pv-#{$t} {
    padding-top: #{$size} !important;
  }

  .pb-#{$t},
  .pv-#{$t} {
    padding-bottom: #{$size} !important;
  }

  .pr-#{$t},
  .ph-#{$t} {
    padding-right: #{$size} !important;
  }

  .pl-#{$t},
  .ph-#{$t} {
    padding-left: #{$size} !important;
  }

  .gap-#{$t} {
    gap: #{$t}px !important;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sm-text {
  font-size: 0.8em;
}

.mid-text {
  font-size: 1.3em;
}

.big-text {
  font-size: 1.6em;
}

.gg-text {
  font-size: 2em;
}

.ls-5 {
  letter-spacing: 5px;
}

.ls-16 {
  letter-spacing: 16px;
}

.lh-30 {
  line-height: 30px;
}

@for $i from 3 to 100 {
  $t: $i;
  $size: calculateRem(#{$t}, rem);
  $height: calculateRem(#{$t + $t/3}, rem);

  .fn-#{$t} {
    font-size: $size !important;
    font-weight: normal !important;
    line-height: $height !important;
  }

  .f200-#{$t} {
    font-size: $size !important;
    font-weight: 200 !important;
    line-height: $height !important;
  }

  .f300-#{$t} {
    font-size: $size !important;
    font-weight: 300 !important;
    line-height: $height !important;
  }

  .f400-#{$t} {
    font-size: $size !important;
    font-weight: 400 !important;
    line-height: $height !important;
  }

  .f500-#{$t} {
    font-size: $size !important;
    font-weight: 500 !important;
    line-height: $height !important;
  }

  .f600-#{$t} {
    font-size: $size !important;
    font-weight: 600 !important;
    line-height: $height !important;
  }

  .f700-#{$t} {
    font-size: $size !important;
    font-weight: bold !important;
    line-height: $height !important;
  }

  .f800-#{$t} {
    font-size: $size !important;
    font-weight: bold !important;
    line-height: $height !important;
  }

  .fb-#{$t} {
    font-size: $size !important;
    font-weight: bold !important;
    line-height: $height !important;
  }
}

.lh-20 {
  line-height: 20px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.os {
  overflow: scroll;
}

.overflow-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-auto {
  overflow: auto;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

/* Buttons */

.btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0px 35px !important;
  cursor: pointer;
  border-radius: 10px !important;
  width: auto;
  min-width: 100px;
  height: 48px;
}

.btn-primary {
  --bs-btn-bg: #003cff !important;
  --bs-btn-border-color: #003cff !important;
  --bs-btn-disabled-bg: #003cff !important;
  --bs-btn-disabled-border-color: #003cff !important;
  --bs-btn-hover-bg: #003cffc9 !important;
  --bs-btn-hover-border-color: #003cffc9 !important;
  --bs-btn-disabled-color: #aec1ff !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: #003cff4d !important;

  .icon {
    background-color: #ffffff !important;
  }
}

.btn-primary:disabled {
  .icon {
    background-color: #aec1ff !important;
  }
}

.btn-md {
  height: 48px;
  line-height: 48px;
}

.btn-outline-primary {
  --bs-btn-color: #003cff !important;
  --bs-btn-border-color: #003cff4d !important;
  --bs-btn-hover-bg: #003cff !important;
  --bs-btn-hover-border-color: #003cff4d !important;

  &:hover {
    .icon {
      background-color: #ffffff !important;
    }
  }

  .icon {
    background-color: #003cff !important;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    padding: 15px;
  }
}

.btn.mid {
  padding: 15px 20px;
}

.btn.big {
  font-size: 1.2em;
  padding: 18px 40px;
}

.btn.full {
  display: block;
  width: 100%;
  text-align: center;
}

.w-auto {
  width: auto !important;
}

.w-fit {
  width: fit-content !important;
}

.w-inherit {
  width: inherit !important;
}

.w-100 {
  width: 100%;
}

.w-350px {
  width: 350px;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-90 {
  height: 90%;
}

.pointer {
  cursor: pointer;
}

/* Buttons */

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $dustyGrayColor !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $dustyGrayColor !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $dustyGrayColor !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $dustyGrayColor !important;
}

.br-20 {
  border-radius: 20px;
}

.br-30 {
  border-radius: 30px;
}

.ob-cover {
  object-fit: cover;
}

.ob-contain {
  object-fit: contain;
}

.scrollfit::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #ededed;
}

.scrollfit::-webkit-scrollbar-thumb {
  background-color: #003cff;
  border-radius: 5px;
}

.scrollfit::-webkit-scrollbar-track {
  background-color: #ededed;
}

.form-container {
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 40px 28px;

  .group-input {
    width: 100%;
    min-height: 75px;
  }

  input,
  textarea,
  select {
    width: 100%;
    background: #f9f9f9;
  }
}

.filtro-consulta {
  background: #ffffff;
  border: 0.5px solid #e4e4e4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 30px 30px 40px 25px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  //gap: 100px;

  .interval {
    padding-top: 40px;
  }

  .input-padding {
    padding-right: 40px;
  }

  .button-filtrar {
    margin-top: 20px !important;
    width: 180px;
  }

  .button-meus-processos {
    margin-top: 20px !important;
    width: 250px;
  }

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 50px;
    padding: 30px 15px;
  }
}

.table-mobile {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .item {
    background: #ffffff;
    border: 1px solid rgba(#d8d8d8, 0.3);
    box-shadow: 0px 4px 10px rgb(0 0 0 / 3%);
    border-radius: 10px;
    padding: 25px 22px;

    .linha {
      padding: 15px 0;
      border-bottom: 1px solid rgba(#d8d8d8, 0.5);
      color: #636363;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      span {
        color: #000000;
        text-align: right;
      }
    }
  }

  .position-top {
    bottom: 40px;
    left: -50px;
  }

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.table-desktop {
  margin-top: 10px;
  display: none;
  border-collapse: separate;
  border-spacing: 0 5px;

  thead {
    color: #636363;
  }

  td {
    padding: 10px;
  }

  th {
    padding: 0 10px;
  }

  tbody {
    tr {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
      height: 60px;
    }

    td {
      border-top: 1px solid rgba(#d8d8d8, 0.3);
      border-bottom: 1px solid rgba(#d8d8d8, 0.3);
      vertical-align: middle;
      //border-color: red;
      height: auto;
      word-break: break-all;

      &:first-child {
        border-left: 1px solid rgba(#d8d8d8, 0.3);
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-right: 1px solid rgba(#d8d8d8, 0.3);
        border-radius: 0 10px 10px 0;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .dropdown-submenu {
      &.position-top {
        bottom: 44px;
        top: auto;
      }

      right: -2px;
      top: 42px;
      background: #ffffff;
      border: 1px solid rgb(191 191 191 / 30%);
      box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
      border-radius: 10px;
      width: 220px;
      padding: 5px 10px;

      [role="menuitem"] {
        &.disabled {
          color: #9c9c9c !important;

          .icon {
            background-color: #9c9c9c !important;
          }
        }
      }

      .display-none {
        display: none !important;
      }

      .close {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    display: table !important;
  }
}

.view-listagem {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .container-search-filter {
    width: 100%;

    .search {
      width: 100%;

      input {
        max-width: 100%;
      }

      .is-loader {
        margin: 0 auto;
      }

      button {
        cursor: pointer;
      }
    }

    .filtros {
      width: 110px;
      justify-content: center;
    }
  }

  @media screen and (min-width: 1280px) {
    .mobile {
      display: none !important;
    }

    .desktop {
      display: inherit !important;
    }

    .container-search-filter {
      width: inherit;

      .search {
        width: 100%;

        input {
          max-width: 395px;
        }
      }

      .filtros {
        width: 187px !important;
        justify-content: space-between !important;
      }
    }
  }
}

.dropzone {
  width: 100%;
  height: 185px;
  background: #fbfbfb;
  border: 1px dashed #ababab;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;

  p {
    margin: 0;
    color: #cecece;
  }
}

.is-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid $blueColor;
  width: 20px;
  height: 20px;
  animation: spin 0.5s linear infinite;
}

.is-preloader {
  width: 100%;
  background: #f5f3f33d;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 9;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 25px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

:root {
  .component {
    &.pagination {
      .pagination-container {
        width: fit-content;
        margin: auto;

        .pagination {
          margin: 15px auto;
          display: flex;
          list-style: none;
          outline: none;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 4px;
          color: #0d6efd;
          height: 32px;
          align-items: center;

          li {
            width: 32px;
            height: 32px;
            border: 1px solid #dee2e6;
            display: flex;
            justify-content: center;
            padding: 0;
            align-items: center;

            &.previous {
              border-radius: 4px 0px 0px 4px;
            }

            &.next {
              border-radius: 0px 4px 4px 0px;
            }

            &.active {
              background: #0000000d;
            }

            &.disabled {
              a {
                opacity: 0.5;
              }
            }
          }
        }

        .pagination>.active>a {
          color: #ffc107;
        }

        .pagination>li>a {
          outline: none;
          cursor: pointer;
        }

        .pagination>.active>a,
        .pagination>.active>span,
        .pagination>.active>a:hover,
        .pagination>.active>span:hover,
        .pagination>.active>a:focus,
        .pagination>.active>span:focus {
          outline: none;
        }

        .pagination>li>a,
        .pagination>li>span {
          color: var(--purple-color);
        }

        .pagination>li:first-child>a,
        .pagination>li:first-child>span,
        .pagination>li:last-child>a,
        .pagination>li:last-child>span {
          border-radius: unset;
        }

        .disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .toggle input:disabled+.slider {
    background-color: #f9f9f9 !important;
  }

  .toggle input:disabled~.labels::before {
    color: #d2d9e4 !important;
  }

  .toggle input:checked~.is-loader {
    float: right;
    margin-top: 5px;
    margin-right: 0px;
    height: 26px;
    width: 26px;
  }

  .toggle input:not(:checked)~.is-loader {
    float: left;
    margin-top: 5px;
    margin-right: 0px;
    height: 26px;
    width: 26px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    opacity: 0.1;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #84a1ff;
    -webkit-box-shadow: inset 0 0 6px #84ceff;
    cursor: pointer;
  }

  .alert {
    --bs-alert-color: #003cff;
    --bs-alert-bg: #ffffff;
    --bs-alert-border-color: #f1f1f1;
    max-width: 300px;
    padding: 10px;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 4%);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .alert-message {
      display: flex;
      flex-direction: column;
      width: 90%;
      line-height: 20px;
    }

    .alert-close {
      display: flex;
      flex-direction: row-reverse;
      width: 10%;

      button {
        border: 0;
        background: transparent;
        margin: 0;
        padding: 0;
        height: auto;
        width: auto;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
}

@media print {
  .nao-imprimir {
    display: none;
  }
}

/* mobile rules */

@media screen and (max-width: 768px) {
  .visible-mobile {
    display: block !important;
  }

  .visible-desktop {
    display: none;
  }

  .mail-visible-mobile {
    display: visible !important;
  }

  .mail-visible-desktop {
    display: none;
  }

  .dropdown-submenu {
    &.position-top {
      bottom: 44px;
      top: auto;
    }

    right: -2px;
    top: 42px;
    background: #ffffff;
    border: 1px solid rgb(191 191 191 / 30%);
    box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
    border-radius: 10px;
    width: 200px;
    padding: 5px 10px;

    [role="menuitem"] {
      &.disabled {
        color: #9c9c9c !important;

        .icon {
          background-color: #9c9c9c !important;
        }
      }
    }

    .display-none {
      display: none !important;
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

}

/* desktop rules */

@media screen and (min-width: 768px) {
  .visible-mobile {
    display: none;
  }

  .visible-desktop {
    display: block !important;
  }

  .mail-visible-mobile {
    display: none;
  }

  .mail-visible-desktop {
    display: visible !important;
  }
}

.test-environment {
  width: 100%;
  text-align: center;
  height: 20px;
  font-size: 14px;
  background-color: #ffcf00;
  position: fixed;
  z-index: 11;
  padding-top: 3px;
}

.marker {
  border: 2px solid #003cff;
  margin-right: 10px;
}

.mail-li {
  line-height: 1.5rem;
}

.alert-label {
  background-color: #ffcf00;
  height: auto;
  padding-bottom: 10px;
}

.display-none {
  display: none !important;
}

.nao-autorizado {
  margin: auto;
}

.hover-highlight:hover {
  background-color: #84a1ff46;
}

.personalize-search {
  label {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  input {
    border: 1px solid #c9c9c9;
  }
}

@media print {
  #no-print {
    display: none;
  }
}