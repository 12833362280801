@import "../../_colors.scss";

.faq-main {
    margin: auto;
    width: 90%;

    .form-container {
        align-items: start;
    }

    .video {

        video {
            width: 100%;
        }
        video::-webkit-media-controls {
            overflow: hidden !important
        }
        video::-webkit-media-controls-enclosure {
            width: calc(100% + 50px);
            margin-left: auto;
        }
    }

    .divisor {
        margin: 50px 0px;
        width: 100%;
        border: 2px solid gray;
    }

    .relacionados {

        .card {
            width: 100%;
            min-height: 100px;
            border: 1px solid rgba(0, 60, 255, 0.3);
            border-radius: 10px;
            padding: 20px;

            i {
                margin: auto;
            }

        }

        .card:hover {
            border: 1px solid rgba(0, 60, 255);
            cursor: pointer;
        }
    }

    .listagem {


        ul {
            border-left: 3px solid #003cff;

            li {
                width: 100%;
                padding: 10px 20px 10px 10px;
                color: #003cff;
                border: 1px solid transparent;
                cursor: pointer;
                border-radius: 0px;
                line-height: 30px;
            }

            li:hover {
                border: 1px solid rgba(0, 60, 255, 0.3);
            }

            .thumb {
                width: 40px;
                height: 40px;
                background-size: cover;
                padding: 5px;
                margin-right: 10px;
                //background-image: url('../../../../assets/images/playvideo.png');
            }

            .id0 {
                background-image: url('../../../../assets/images/faq/0.jpg');
            }

            .id1 {
                background-image: url('../../../../assets/images/faq/1.jpg');
            }

            .id2 {
                background-image: url('../../../../assets/images/faq/2.jpg');
            }

            .id3 {
                background-image: url('../../../../assets/images/faq/3.jpg');
            }


            .selecionado {
                background-color: #003cff;
                color: white;
            }
        }

    }
}