.login {
  position: relative;

  .login-backgroud {
    background-image: url('../../../assets/images/login.png');
    width: 45vw;
    height: 100%;
    background-attachment: inherit;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: fixed;
    left: 0;
  }

  .login-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
    height: fit-content;

    .sub-titulo {
      max-width: 490px;
    }

    .form-login {
      height: max-content;
      padding: 40px 30px;
      border-radius: 20px;
      width: 400px;
      margin-top: 100px;
    }
  }

  @media screen and (max-width: 1366px) {
    .login-form .form-login {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 1200px) {
    .login-backgroud {
      width: 50vw;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 100% !important;

    .login-backgroud {
      width: 100%;
      height: 215px;
      position: absolute;
    }

    .login-form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;

      .logo-img {
        margin-top: 20px !important;
      }

      .form-login {
        margin-top: 0 !important;
      }
    }

    .sub-titulo {
      display: none;
    }

    .btn-login {
      flex-direction: column-reverse;

      button {
        width: 100% !important;
        justify-content: center !important;
        gap: 10px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .form-login {
      width: 100% !important;
    }
  }
}