@import "../_colors.scss";

.email {
    margin: auto;
    margin: 4% 10% 2% 10%;

    table {
        text-align: center;

        th {
            width: auto;
            border: 1px solid black;
            font-weight: 600;
            padding: 20px;
            vertical-align: middle !important;
        }

        td {
            width: auto;
            border: 1px solid black;
            padding: 20px;
            vertical-align: middle !important;
        }
    }

    p {
        text-align: justify;
    }
}

.print-email {
    margin: auto;
    margin-right: 2%;
    padding-bottom: 50px;

    table {
        text-align: center;

        th {
            width: auto;
            border: 1px solid black;
            font-weight: 600;
            padding: 20px;
            vertical-align: middle !important;
        }

        td {
            width: auto;
            border: 1px solid black;
            padding: 20px;
            vertical-align: middle !important;
        }
    }

    p {
        text-align: justify;
    }
}

.display-block {
    display: block !important;
}

.display-none {
    display: none;
}

.image-ops {
    width: 150px;
    height: 100px;
}

hr {
    width: 100%;
    border-bottom: 2px solid gray !important;
}

.acqua-mark {
    position: relative;
}

.acqua-mark::before {
    content: "";
    -webkit-print-color-adjust: exact !important;
    background-image: url('../../../assets/images/sign.jpeg');
    background-attachment: inherit;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    padding: 3px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}